/**
 * Constants for error categories
 *
 * @namespace constants.errorCategories
 */
export default {
  contentRestricted: 'content_restricted',
  player: 'player',
  provision: 'provision',
  heartbeat: 'heartbeat',
  unsupportedDevice: 'unsupported_device',
  geofenceError: 'geofence_error',
  offline: 'offline'
};
