/**
 * @module Main
 * @desc Main entry point
 */
import 'global.css';
import 'components/cast-idle';
import 'components/cast-message';
import 'components/cast-error-message';
import 'components/cast-player-overlay';
import 'components/cast-entity-metadata';
import 'components/cast-scrubber';
import 'components/cast-up-next';

import app from 'app';

import player from 'player';
import { i18nInit } from 'i18n';
import config from 'config';
import castMessenger from 'cast-messenger';
import errors from 'constants/errors';
import Sundog from 'sundog/main';

i18nInit();

/** player Init Promises */
const playerInitPromise = player.init();

/**
 * config loads partner configs
 */
config.load().then(
  async () => {
    castMessenger.connect();
    if (config.environment.ENABLE_SUNDOG === 'true') {
      const doggo = new Sundog();
      await doggo.init(app);
      app.sundog = doggo;
    }
    await playerInitPromise;

    app.startup();
  },
  () => {
    // TODO: Config failed to load. This needs to be logged and reported (it shouldn't happen)
    document.querySelector('cast-idle').state = 'failed';

    const errorMessage = document.createElement('cast-error-message');
    errorMessage.errorDetails = errors.config;
    document.querySelector('#message').appendChild(errorMessage);
  });
