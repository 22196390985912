import BaseModel from 'model/base';

class StreamModel extends BaseModel {
  static async _propertiesFromResource(resource) {
    const props = resource.getProps();

    return {
      ...await super._propertiesFromResource(resource),
      ...props,
      contentUrl: resource.getFirstAction('contentUrl').getRawActionUrl()
    };
  }

  /**
   * Build stream from Hypergard resource
   *
   * @param {object} resource - Hypergard resource
   * @return {StreamModel}
   */
  static async fromResource(resource) {
    return new StreamModel(await StreamModel._propertiesFromResource(resource));
  }

  static async fromUrl(url) {
    return new StreamModel({ contentUrl: url });
  }
}

export default StreamModel;
