import ContentProviderModel from 'model/content-provider';
import WatchableModel from 'model/watchable';
import TransactionDetailsModel from 'model/transaction-details';
import watchableTypes from 'constants/watchable-types';

const getTransactionDetails = async (resource) => {
  if (resource.hasEmbedded('transactionDetails') || resource.hasAction('transactionDetails')) {
    const transactionResponse = await resource.fetchEmbedded('transactionDetails');
    const transactionDetails = await TransactionDetailsModel.fromResource(transactionResponse.data);

    return {
      transactionDetails,
      _type: transactionDetails.type ==='purchased' ? watchableTypes.Purchase : watchableTypes.Vod
    };
  }
};

const getContentProvider = async (resource) => {
  if (resource.hasEmbedded('contentProvider') || resource.hasAction('contentProvider')) {
    const contentProviderResponse = await resource.fetchEmbedded('contentProvider');
    return ContentProviderModel.fromResource(contentProviderResponse.data);
  }
};

/**
 * VOD object
 *
 * @hideconstructor
 */
class VodModel extends WatchableModel {
  static async _propertiesFromResource(resource) {
    const props = resource.getProps();
    const [inheritedProps, transactionDetails, contentProvider] = await Promise.all([
      super._propertiesFromResource(resource),
      getTransactionDetails(resource),
      getContentProvider(resource)
    ]);

    return {
      ...inheritedProps,
      ...transactionDetails,
      contentProvider,
      adBrand: inheritedProps['adBrand'] || props.adBrand,
      assetId: inheritedProps['assetId'] || props.assetId,
      auditudeId: inheritedProps['auditudeId'] || props.auditudeId,
      derivedTitle: props.derivedTitle,
      isHD: props.isHD,
      mediaId: inheritedProps['mediaId'] ||props.mediaId,
      paid: inheritedProps['paid'] || props.paid,
      providerId: inheritedProps['providerId'] || props.providerId,
      rating: props['contentRating/detailed'],
      isAdult: props.isAdult
    };
  }

  /**
   * Build VOD watchable from Hypergard resource
   *
   * @param {object} resource - Hypergard resource
   * @return {VodModel}
   */
  static async fromResource(resource) {
    return new VodModel(await VodModel._propertiesFromResource(resource));
  }
}

WatchableModel.addType(VodModel, ({ _type }) =>
  _type === watchableTypes.Vod);

export default VodModel;
