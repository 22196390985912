import ContentProviderModel from 'model/content-provider';
import WatchableModel from 'model/watchable';
import watchableTypes from 'constants/watchable-types';

const getContentProvider = async (resource) => {
  if (resource.hasEmbedded('contentProvider') || resource.hasAction('contentProvider')) {
    const response = await resource.fetchEmbedded('contentProvider');
    return ContentProviderModel.fromResource(response.data);
  }
};

const getOttTimeData = (props) => {
  if (props.liveWindowEndTime) {
    return {
      liveWindowEndTime: Number(props.liveWindowEndTime),
      startTime: Number(props.availableTime),
      endTime: Number(props.liveWindowEndTime)
    };
  }
};

/**
 * TVE object
 *
 * @hideconstructor
 */
class TveModel extends WatchableModel {
  static async _propertiesFromResource(resource) {
    const props = resource.getProps();
    const [inheritedProps, contentProvider] = await Promise.all([
      super._propertiesFromResource(resource),
      getContentProvider(resource)
    ]);

    return {
      ...inheritedProps,
      ...(getOttTimeData(props) || {}),
      contentProvider,
      derivedTitle: props.derivedTitle,
      isHD: props.isHD,
      rating: props['contentRating/detailed'],
      isAdult: props.isAdult
    };
  }

  /**
   * Build TVE watchable from Hypergard resource
   *
   * @param {object} resource - Hypergard resource
   * @return {TveModel}
   */
  static async fromResource(resource) {
    return new TveModel(await TveModel._propertiesFromResource(resource));
  }
}

WatchableModel.addType(TveModel, ({ _type }) =>
  _type === watchableTypes.Tve);

export default TveModel;
