/**
 * @module resumePoints
 */
import { dispatches } from 'lib/event-dispatcher';
import Logger from 'lib/logger';
import api from 'api';

const updateEndpoint = 'updateResumePoint';
const getEndpoint = 'getResumePoints';

const logger = new Logger('RESUME');

/* eslint-disable valid-jsdoc */
/**
 *  XTVAPI Resume point
 * @type {Object}
 */
/* eslint-enable valid-jsdoc */
@dispatches('resumePoints')
class ResumePoints {
  resumePoints = {};

  /**
   * Fetch all resume points for user
   * @return {Promise} xtvapi get resume points promise
   */
  getAllResumePoints = async () => {
    try {
      return await api.send({
        endpoint: getEndpoint,
        params: {
          maxDaysOld: 60 // TODO : some dynamic getter for max days
        },
        suppressLogging: true
      }).then( (resolve) => this.parseResumePoints(resolve) );
    } catch (error) {
      const xtvError = error.xhr.xtv;
      // TODO make a constants file for resume types
      this.dispatchEvent('failedGetResumePoint', { xtvError });
    }
  }
  /**
  * get single resume point given a mediaId
  * @param  {string} mediaId
  * @return {number} resume point for asset
  */
  async getResumePoint( mediaId ) {
    if (!mediaId ) {
      logger.warn('ResumePoint: mediaId not provided. Unable to fetch resume point');
      return 0;
    }
    try {
      if (Object.keys(this.resumePoints).length === 0 ) {
        await this.getAllResumePoints();
      }
    } catch (error) {
      logger.error('Fetching Resume Points failed', error);
      return 0;
    }

    if (!this.resumePoints[mediaId]) {
      return 0;
    }

    return this.resumePoints[mediaId].position || 0;
  }

  /**
  * save resume point given a mediaid, programid and player position
  * @param  {string}  mediaId   watchable.mediaId
  * @param  {string}  programId entity.programId
  * @param  {number}  progress  player position to save
  * @return {Promise}
  */
  updateResumePoint = async ({ mediaId, programId, progress }) => {
    if (!this.resumePoints[mediaId]) {
      this.resumePoints[mediaId] = {};
    }

    this.resumePoints[mediaId].position = progress;

    try {
      return await api.send({
        endpoint: updateEndpoint,
        params: {
          mediaId: mediaId,
          programId: programId,
          progress: Math.floor(progress)
        },
        suppressLogging: true
      });
    } catch (error) {
      const xtvError = error.xhr.xtv;
      // TODO make a constants file for resume types
      this.dispatchEvent('failedUpdateResumePoint', { xtvError });
    }
  }

  parseResumePoints({ resource }) {
    if (resource.getEmbedded('resumePointGroups')) {
      const rps = resource.getEmbedded('resumePointGroups').map(function(item) {
        return item.getEmbedded('lastUpdatedResumePoint').getProps();
      });
      this.resumePoints = this._createMap(rps);
    }
  }

  _createMap(rps) {
    const updatedResumePoints = {};
    let updated = false;

    rps.forEach((rp) => {
      if ((this.resumePoints[rp.mediaId] || {}).dateUpdated !== rp.dateUpdated) {
        updated = true;
      }
      updatedResumePoints[rp.mediaId] = rp;
    });

    if (updated) {
      logger.log('RESUME POINT NEEDED UPDATING!!');
      // TODO dispatch event or something if anyone cares this updated
    }
    return updatedResumePoints;
  }
}
export default new ResumePoints();
export { ResumePoints };
