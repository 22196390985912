/**
 * Constants for messages sent with Cast API between Sender and Receiver
 *
 * @namespace constants.messageTypes
 */
export default {
  // Sender -> Receiver message types
  getTimelineData: 'GET_TIMELINE_DATA',
  promptResponse: 'PROMPT_RESPONSE',
  requestAuth: 'REQUEST_AUTH',
  setDebug: 'SET_DEBUG',
  setParentalControls: 'SET_PARENTAL_CONTROLS',
  setSenderConfig: 'SET_SENDER_CONFIG',
  setTrickPlay: 'SET_TRICKPLAY',
  simulateEas: 'SIMULATE_EAS',
  simulateError: 'SIMULATE_ERROR',
  simulatePrompt: 'SIMULATE_PROMPT',
  triggerLinearBoundary: 'TRIGGER_LINEAR_BOUNDARY',

  // Receiver -> Sender message types
  adProgress: 'AD_PROGRESS',
  authResponse: 'AUTH_RESPONSE',
  debugSender: 'DEBUG_SENDER',
  error: 'ERROR',
  mediaEnded: 'MEDIA_ENDED',
  mediaOpened: 'MEDIA_OPENED',
  parentalControlsUpdateResponse: 'PARENTAL_CONTROLS_UPDATE_RESPONSE',
  pinFailed: 'PIN_FAILED',
  senderConfigUpdateResponse: 'SENDER_CONFIG_UPDATE_RESPONSE',
  showPrompt: 'SHOW_PROMPT',
  timelineData: 'TIMELINE_DATA',

  // Events from cast API
  senderConnected: 'SENDER_CONNECTED',
  senderDisconnected: 'SENDER_DISCONNECTED',
  shutdown: 'SHUTDOWN',

  // Internal events for the receiver
  controllerCreated: 'controllerCreated'
};
