import castMessenger from 'cast-messenger';
import messageTypes from 'constants/message-types';
import config from 'config';

/**
 * sendDebugger - manages sending debug messages to sender
 * manages google debuggin module enable/disable
 */
class SenderDebugger {
   currentlySending = false;
   senderListeners = {
     [messageTypes.setDebug]: async ({ detail }) => {
       this.sendDebugMessage('SET DEBUG - SENDER DEBUGGER CALLED - GOOGLE DEBUG', detail);
       if (detail.googleDebugToggle !== undefined) {
         const castDebugLogger = window.cast.debug.CastDebugLogger.getInstance();
         const googleDebugEnabled = detail.googleDebugToggle;
         castDebugLogger.setEnabled(googleDebugEnabled);
         return;
       }
     }
   };
   addDebugCastListeners(castMessengerTarget) {
     this.sendDebugMessage('[SenderDebugger] ADD CAST LISTENERS', this.senderListeners);
     castMessengerTarget.addEventListenerCollection(this.senderListeners);
   }
   constructor() {
     const cast = window.cast;
     if (!cast) {
       // for unit tests
       return;
     }
     const castDebugLogger = cast.debug.CastDebugLogger.getInstance();

     const context = cast.framework && window.cast.framework.CastReceiverContext.getInstance();
     console.log('CONSTRUCTOR SENDEr Rec ENABLED: ' + castDebugLogger);
     context.addEventListener(cast.framework.system.EventType.READY, () => {
       if (castDebugLogger.debugOverlayElement_ !== undefined) {
         // Enable debug logger and show a 'DEBUG MODE' overlay at top left corner.
         console.log('GOOGLE DEBUG SET ENABLED');
         this.sendDebugMessage('GOOGLE DEBUG', {
           detailData: `castDebugLogger state: ${castDebugLogger.debugOverlayElement_} `
         });
         // TODO - fix sender UI button
         if (config.environment.ENABLE_CAC_DEBUG === 'true') {
           castDebugLogger.setEnabled(true);
         }
       }
     });
     if (castDebugLogger) {
       // TODO Add UI controls to add/remove these log levels
       castDebugLogger.loggerLevelByEvents = {
         'cast.framework.events.category.CORE': window.cast.framework.LoggerLevel.DEBUG,
         'cast.framework.events.category.DEBUG': window.cast.framework.LoggerLevel.DEBUG,
         'cast.framework.events.category.FINE': window.cast.framework.LoggerLevel.DEBUG,
         'cast.framework.events.category.REQUEST': window.cast.framework.LoggerLevel.DEBUG
       };
     }
   }
   /**
     * Takes a debug message object and dispatches to sender
     * @param {string} message Message title
     * @param {string} detail json object of data to be sent to sender
     * message: string
     */
   sendDebugMessage( message, detail ) {
     try {
       castMessenger.broadcastMessage(messageTypes.debugSender, { debugData:
      {
        message: message,
        detail: detail
      } });
     } catch (error) {
       castMessenger.broadcastMessage(messageTypes.debugSender, { debugData:
            {
              message: message,
              detail: { text: 'invalid detail data ' }
            } });
     }
   }
}

export const senderDebugger = new SenderDebugger();
export const sendDebugMessage = senderDebugger.sendDebugMessage.bind(this);
