export default {
  Channel: 'TelevisionStation/Channel',
  Listing: 'TVListing',
  ListingDetail: 'TVListingDetail',
  Purchase: 'Purchase',
  Recording: 's:VideoObject/Recording',
  Tve: 'TveVideo',
  TvSeries: 's:TvSeries',
  Ott: 'OTT',
  Vod: 's:VodItem',
  ExternalStream: 'ExternalStream'
};
