/**
 * @module API
 */

import HyperGard from 'hypergard';
import authTokenProvider from 'lib/auth-token-provider';
import splunkLogger from 'lib/telemetry/splunk-logger';

import deepExtend from 'hypergard/lib/extend';

/**
 * Wrapper for working with XTV API through HyperGard
 */
class API {
  /**
   * @param {string} endpoint - XTV API root URL
   * @param {object} config
   * @param {object} config.hypergard - Configuration object to pass to HyperGard
   * @param {function[]} [config.middleware] - Middleware functions to apply
   */
  constructor(endpoint, config) {
    this.config = config;
    this.unauthHomepage = null;

    this.hypergard = new HyperGard(endpoint, config.hypergard);

    if (config.middleware) {
      this.hypergard.applyMiddlewareStack([...config.middleware]);
    }

    this.fetch = async (url, options) => {
      const response = await fetch(url, options);

      try {
        return {
          data: this.hypergard.parse(await response.json()),
          xhr: response
        };
      } catch (error) {
        return {
          data: response.text(),
          xhr: response
        };
      }
    };

    this.fetch = [
      (url, options, next) => next(url, deepExtend({}, config.hypergard.xhr, options)),
      ...(config.middleware || [])
    ].reduceRight(
      (load, mw) => (url, options) => mw(url, options, load),
      this.fetch);
  }

  /**
   * Request an endpoint
   *
   * @param {object} options
   * @param {string} options.endpoint - Link to request from API homepage
   * @param {boolean} [options.allowUnauth=false] - Allow request to use unauthenticated homepage
   * @param {boolean} [options.useUnauth=false] - Force request to use unauthenticated homepage
   * @param {object} [options.params={}] - Parameters to pass to HyperGard.getFirstAction for endpoint link
   * @param {object} [options.options={}] - Additional options to pass to HyperGard.fetch
   * @param {boolean} [options.fatal=false] - Request failure is fatal
   * @param {number} [options.retry=1] - Number of times to attempt request before failing
   * @param {boolean} [options.suppressLogging=false] - Supress logging of request details
   *
   * @return {object} - Response object
   */
  async send({
    allowUnauth,
    useUnauth,
    endpoint,
    params,
    options,
    fatal,
    retry,
    suppressLogging
  }) {
    const req = {
      // getUserHomepage request options
      allowUnauth: !!allowUnauth,
      useUnauth: !!useUnauth,

      // callHypergard request options
      fetch: true,
      endpoint,
      params: params || {},
      options: {
        endpoint,
        fatal: !!fatal,
        retry: retry || 0,
        suppressLogging: !!suppressLogging,
        ...options
      }
    };

    return this.callHypergard(req);
  }

  logHomepageSuccess(homepage) {
    if (!this.homepageLogged) {
      splunkLogger.onSuccess({ ...homepage, endpoint: 'homepage' });
    }
    this.homepageLogged = true;
  }

  async callHypergard(request) {
    const { fetch, endpoint, params, options } = request;
    const homepage = await this.getUserHomepage(request);
    const action = homepage.data.getFirstAction(endpoint, params);

    this.logHomepageSuccess(homepage);

    if (!fetch) {
      return action.getActionUrl();
    }

    const result = await action.fetch(options);
    const data = {
      endpoint,
      request,
      resource: result.data,
      xhr: result.xhr
    };

    if (!options.suppressLogging) {
      splunkLogger.onSuccess(data);
    }

    return data;
  }

  async getHomepage() {
    return this.hypergard.fetch();
  }

  async getUserHomepage({ allowUnauth, useUnauth }) {
    if ((authTokenProvider.authTokenSet || !allowUnauth) && !useUnauth) {
      return this.getHomepage();
    }

    if (!this.unauthHomepage) {
      const homepage = await this.getHomepage();
      this.unauthHomepage = homepage.data.getFirstAction('unauthenticatedHomePage').fetch();
    }

    return this.unauthHomepage;
  }
}

export default API;
