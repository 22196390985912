import { LitElement, html, css, customElement, property } from 'lit-element';
import { buildSrcSet } from 'lib/helpers';

/**
 * cast-entity-metadata component
 *
 * Metadata for the currently playing entity
 *
 * @class cast-entity-metadata
 * @hideconstructor
 */
@customElement('cast-entity-metadata')
class EntityMetadata extends LitElement {
  /**
   * Entity details
   *
   * @name cast-entity-metadata#creativeWork
   * @type {object|null}
   * @default null
   */
  @property({ type: Object })
  creativeWork = null;

  /**
   * Content provider or channel logo
   *
   * This is a Hypergard action
   *
   * @name cast-entity-metadata#logo
   * @type {object|null}
   * @default null
   */
  @property({ type: Object })
  logo = null;

  static styles = css`
    /* Make opacity work on the component */
    :host > * {
      opacity: inherit;
      transition: opacity 0.25s;
    }

    .title {
      color: var(--tv-grey-18);
      font-size: 4.8rem;
      font-weight: 400;
      white-space: nowrap;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .details {
      color: var(--tv-grey-36);
      font-size: 3.6rem;
      font-weight: 400;
      white-space: nowrap;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .logo img {
      width: 10rem;
      height: 5rem;
    }
  `;

  logoUrl(params) {
    return this.logo.setParams({
      gravity: 'SouthWest',
      ...params
    }).getActionUrl();
  }

  getDetails() {
    if (this.creativeWork.isTvEpisode()) {
      const { season, episode, title } = this.creativeWork;
      const hasSeason = season !== undefined;
      const hasEpisode = episode !== undefined;
      const separator = ((hasSeason || hasEpisode) && title) ? ' - ' : null;
      return html`
        ${ hasSeason ? html`S${ season }` : null }
        ${ hasEpisode ? html`Ep${ episode }` : null }
        ${ separator }
        ${ title }
      `;
    }

    return null;
  }

  getLogoImage() {
    if (!this.logo) {
      return null;
    }

    const logoSrcSet = buildSrcSet({
      '1x': this.logoUrl({ width: 67, height: 33 }),
      '1.5x': this.logoUrl({ width: 100, height: 50 }),
      '3x': this.logoUrl({ width: 200, height: 100 })
    });

    return html`<img alt="" srcset="${ logoSrcSet }"/>`;
  }

  render() {
    return html`
      <div class="logo">${ this.getLogoImage() }</div>
      ${ this.creativeWork && html`
        <div class="title">${ this.creativeWork.series ? this.creativeWork.series.title : this.creativeWork.title }</div>
        <div class="details">${ this.getDetails() }</div>
      ` }
    `;
  }
}

export default EntityMetadata;
