import api from 'api';

import WatchableModel from 'model/watchable';
import CreativeWorkModel from 'model/creative-work';
import ChannelModel from 'model/channel';
import watchableTypes from 'constants/watchable-types';

let listingCache = {};

const getChannel = async (resource) => {
  if (resource.hasAction('channel')) {
    const channelLink = resource.getFirstAction('channel').getRawActionUrl();
    return ChannelModel.loadFromSelfLink(channelLink);
  }
};

/**
 * Listing object
 *
 * @hideconstructor
 */
class ListingModel extends WatchableModel {
  static async _propertiesFromResource(resource) {
    const props = resource.getProps();
    const [inheritedProps, channel] = await Promise.all([
      super._propertiesFromResource(resource),
      getChannel(resource)
    ]);

    const result = {
      ...inheritedProps,
      channel,
      listingId: props.listingId,
      entityId: props.entityId,
      startTime: Number(props.startTime),
      endTime: Number(props.endTime),
      derivedTitle: props.title,
      isHD: props.isHD,
      isSubjectToBlackout: props.isSubjectToBlackout,
      type: props.type,
      rating: (channel && channel.enforceParentalControlsOnChannel)
        ? channel.rating
        : props['contentRating/detailed'],
      isAdult: props.isAdult
    };

    return result;
  }

  /**
   * Build listing from Hypergard resource
   *
   * @param {object} resource - Hypergard resource
   * @return {ListingModel}
   */
  static async fromResource(resource) {
    const self = resource.getFirstAction('self').getRawActionUrl();

    if (!listingCache[self]) {
      listingCache[self] = new ListingModel(await ListingModel._propertiesFromResource(resource));
    }

    return listingCache[self];
  }

  /**
   * Reset caches
   *
   * Resets the listing cache
   */
  static resetCache() {
    listingCache = {};
  }

  /**
   * Load listing details and update listing model
   */
  async loadDetails() {
    if (this.detailsLoaded) {
      return true;
    }

    const response = await api.send({
      endpoint: 'getTvListingDetail',
      params: {
        listingId: this.listingId,
        channelId: this.channel.channelId
      }
    });
    const resource = response.resource;

    Object.assign(this, {
      creativeWork: await CreativeWorkModel.fromResource(resource.getEmbedded('encodesCreativeWork'))
    });

    this.detailsLoaded = true;
  }

  async loadCreativeWork() {
    // loadDetails existed first, then loadCreativeWork was added to Watchable
    return this.loadDetails();
  }
}

WatchableModel.addType(ListingModel, ({ _type }) =>
  [watchableTypes.Listing, watchableTypes.ListingDetail].includes(_type));

export default ListingModel;
