import api from 'api';
import BaseModel from 'model/base';
import CreativeWorkModel from 'model/creative-work';
import StreamModel from 'model/stream';
import watchableTypes from 'constants/watchable-types';
import { timeIsBetween } from '../lib/helpers';

const getStreams = async (resource) => {
  // Every watchable type has `streams`...except Channels which have `stream` as an array
  const streams = resource.getEmbedded('streams') || resource.getEmbedded('stream');

  if (streams) {
    return {
      streams: await Promise.all(streams.map(StreamModel.fromResource))
    };
  }
};

const types = [];

/**
 * Base class for watchable types
 */
class WatchableModel extends BaseModel {
  static async _propertiesFromResource(resource) {
    const props = resource.getProps();
    const [inheritedProps, streams] = await Promise.all([
      super._propertiesFromResource(resource),
      getStreams(resource)
    ]);

    return {
      ...inheritedProps,
      ...streams,
      adBrand: props.adBrand,
      auditudeId: props.auditudeId,
      contentProvider: props.contentProvider,
      duration: props.duration,
      _type: props._type,
      mediaGuid: props.mediaGuid,
      mediaId: props.mediaId,
      providerId: props.providerId,
      streamId: props.streamId,
      castInfo: props.castInfo,
      restrictStreaming: props.restrictStreaming || [],
      trickModesRestricted: props.trickModesRestricted,
      ivod: props.ivod || false
    };
  }

  static addType(typeClass, matcher) {
    types.push({ typeClass, matcher });
  }

  static async fromResource(resource) {
    const props = resource.getProps();
    const type = types.find(({ matcher }) => matcher(props, resource));

    if (!type) {
      throw new Error('No type found for watchable');
    }

    const TypeClass = type.typeClass;

    return new TypeClass(await TypeClass._propertiesFromResource(resource));
  }

  static async fromUrl(url) {
    const response = await api.fetch(url);
    return WatchableModel.fromResource(response.data);
  }

  findPlayableStream() {
    // TODO: Confirm this is the correct logic
    return this.getLinearProp('streams')[0] || this.getLinearProp('stream')[0];
  }

  getErrorCategory() {
    if (this.isLinear()) {
      return 'linear';
    } else if (this.isRecording()) {
      return 'recording';
    } else if (this.isAnyVod()) {
      return 'vod';
    }
  }

  async getExternalPlayerResource(streamIdOverride) {
    const response = await api.send({
      endpoint: 'startExternalTveLinearStream',
      params: {
        streamId: streamIdOverride || this.getLinearProp('streamId')
      },
      suppressLogging: true
    });
    return response.resource;
  }

  async getExternalOttStream(locator) {
    const response = await api.send({
      endpoint: 'startExternalTveVodStream',
      params: {
        mediaId: locator,
        mediaLocator: locator
      },
      suppressLogging: true
    });
    return response.resource;
  }

  async loadCreativeWork() {
    if (this.creativeWork) {
      return;
    }

    if (!this._resource.hasEmbedded('encodesCreativeWork')
      && !this._resource.hasAction('encodesCreativeWork')) {
      return;
    }

    const response = await this._resource.fetchEmbedded('encodesCreativeWork');
    this.creativeWork = await CreativeWorkModel.fromResource(response.data);
  }

  async canStream() {
    const playableStream = this.findPlayableStream();
    const streamId = (playableStream && playableStream.streamId) || this.streamId || '';
    const mediaId = (playableStream && playableStream.mediaId) || this.mediaId || '';

    const canStream = await api.send({
      endpoint: 'canStream',
      params: {
        streamId,
        mediaId
      }
    });

    return canStream;
  }

  getTypeLabel() {
    const streamProviderMapping = {
      'CBS': 'CBS',
      'disney': 'Disney',
      'espn': 'ESPN',
      'HULU': 'Hulu',
      'nbc': 'nbc'
    };

    const isEspnOtt = this.isOTT() && /espn/ig.test((this.findPlayableStream() || {}).contentUrl);
    const streamProvider = (this.findPlayableStream()|| {}).streamProvider;
    const isExternalPlayer = Object.keys(streamProviderMapping).some(function(provider) {
      return streamProvider === provider;
    });

    if (isEspnOtt) {
      return 'OTT';
    } else if (this.isFastLane(this)) {
      return 'FAST';
    } else if (isExternalPlayer) {
      return streamProviderMapping[streamProvider];
    } else if (this.isIvod(this)) {
      return 'IVOD';
    } else if (this.isTveListing(this)) {
      return 'TVE LINEAR';
    } else if (this.isListing(this) && !this.isTveListing(this)) {
      return 'T6 LINEAR';
    } else if (this.isPurchase(this)) {
      return 'PURCHASE';
    } else if (this.isRental(this)) {
      return 'RENTAL';
    } else if (this.isRecording(this)) {
      return 'RECORDING';
    } else if (this.isVod(this)) {
      return 'T6 VOD';
    } else if (this.isTve(this)) {
      return 'TVE VOD';
    }
  }

  getLinearProp(prop) {
    return this[prop] || (this.channel || {})[prop];
  }

  isCastable() {
    return !!(this.getLinearProp('castInfo') || {}).chromecast;
  }

  isAnyVod() {
    return this.isPurchase() || this.isVod() || this.isTve();
  }

  isChannel() {
    return this._type === watchableTypes.Channel;
  }

  isExternalStream() {
    const stream = this.findPlayableStream() || {};
    return stream._type === watchableTypes.ExternalStream;
  }

  isLinear() {
    return this.isChannel() || this.isListing();
  }

  isLinearTve() {
    return this.isTveChannel() || this.isTveListing();
  }

  isListing() {
    return this._type === watchableTypes.Listing || this._type === watchableTypes.ListingDetail;
  }

  isExternalOTT( streamType ) {
    return this.isExternalStream() && ( this.findPlayableStream() || {} ).streamProvider === streamType;
  }

  isFastLane() {
    // Xumo is the first and only 'FAST' lane at the moment.
    return this.isXumoStream();
  }

  isXumoStream() {
    return /xumo/ig.test((this.findPlayableStream() || {}).streamId);
  }

  isOTT() {
    return this._type === watchableTypes.Ott || /ott-stream/.test((this.findPlayableStream() || {}).contentUrl);
  }

  isOTTInProgress() {
    return this.isOTT() && this.liveWindowEndTime &&
    timeIsBetween(this.startTime, this.endTime);
  }

  isTransactional() {
    return !!this.transactionDetails;
  }

  isPurchase() {
    return this._type === watchableTypes.Purchase;
  }

  isRental() {
    return this.isTransactional() && !this.isPurchase();
  }

  isRecording() {
    return this._type === watchableTypes.Recording;
  }

  isRecordingInProgress() {
    return this.isRecording() && timeIsBetween(this.dateRecordedTimestamp, Number(this.dateRecordedTimestamp) + Number(this.duration));
  }

  isRestartable() {
    let isCurrentlyLive = false;

    if (this.isListing()) {
      return false;
    }
    if (this.liveWindowEndTime) {
      isCurrentlyLive = timeIsBetween(this.startTime, this.liveWindowEndTime);
    }

    return this.findPlayableStream().restartable && !isCurrentlyLive;
  }

  isTve() {
    return this._type === watchableTypes.Tve;
  }

  isTveRecording() {
    return this.isRecording() && (this.isTveFlag || (this.channel && this.channel.isTveFlag));
  }

  isTvSeries() {
    return this._type === watchableTypes.TvSeries;
  }

  isTveChannel() {
    return this.isChannel() && !!this.isTveFlag;
  }

  isTveListing() {
    return this.isListing() && !!(this.channel && this.channel.isTveChannel());
  }

  isAnyTve() {
    return this.isLinearTve() || this.isTve() || this.isTveRecording();
  }

  isVod() {
    return this._type === watchableTypes.Vod;
  }

  isIvod() {
    return this.ivod;
  }

  isGeoFenced() {
    const linearStream = this.isLinear() && this.findPlayableStream();

    if (linearStream) {
      return linearStream.streamType === 'GeoFenced' || !!linearStream.geofenced;
    }

    return this.hasOwnProperty('geofenced') && this.geofenced;
  }

  isFFRestricted() {
    return /ff/i.test(this.trickModesRestricted);
  }

  isRWRestricted() {
    return /rw/i.test(this.trickModesRestricted);
  }

  isPauseRestricted() {
    return /pause/i.test(this.trickModesRestricted);
  }
}

export default WatchableModel;
