import api from 'api';

import WatchableModel from 'model/watchable';
import ListingModel from 'model/listing';
import watchableTypes from 'constants/watchable-types';
import SplunkLogger from '../lib/telemetry/splunk-logger';
import Session from '../lib/session';

let channelMapPromise = null;
let channelCache = {};
const localChannels = [];

/**
 * Channel object
 *
 * @hideconstructor
 */
class ChannelModel extends WatchableModel {
  listingCache = {};

  /**
   * Load the channel map
   *
   * Response is cached, so subsequent calls will not make a network request.
   *
   * @return {HypergardResource[]}
   */
  static getChannelMap() {
    if (!channelMapPromise) {
      channelMapPromise = api.send({
        endpoint: 'getChannelMap',
        params: {
          freetome: 'off' // TODO: Do we need to be able to change this?
        },
        suppressLogging: true
      })
        .then((response) => {
          const channelsSource = response.resource.getEmbedded('channels');
          const entitledChannels = channelsSource.map((channel) => channel.getProp('entitled')).filter(Boolean).length;
          const xboAccountId = Session.xboAccountId;
          const lastKnownEntitledCount = localStorage.getItem(`tv-entitled-channels-${xboAccountId}`);
          const channels = {
            entitled: entitledChannels,
            diff: lastKnownEntitledCount ? entitledChannels - lastKnownEntitledCount : 0,
            total: channelsSource.length,
            unentitled: channelsSource.length - entitledChannels
          };
          localStorage.setItem(`tv-entitled-channels-${xboAccountId}`, entitledChannels);
          SplunkLogger.onSuccess({ ...response, channels });
          return channelsSource;
        })
        .catch((error) => {
          channelMapPromise = null;
          throw error;
        });
    }

    return channelMapPromise;
  }

  /**
   * Reset caches
   *
   * Resets the channel map cache
   */
  static resetCache() {
    channelMapPromise = null;
    channelCache = {};
  }

  /**
   * Load channel based on channel ID
   *
   * @param {string} channelId - Channel ID to load
   * @return {ChannelModel}
   */
  static async load(channelId) {
    if (!channelCache[channelId]) {
      const channels = await ChannelModel.getChannelMap();
      let channelResource = channels.find((ch) => ch.getProp('channelId') === channelId);

      if (!channelResource) {
        console.warn(`Attempting to find channelId=${ channelId } in local channel map`);
        const localChannel = await ChannelModel.getLocalChannelById(channelId);
        if (localChannel) {
          channelResource = channels.find((ch) => ch.getProp('companyId') === localChannel.companyId && ch.getProp('isTve'));
        }
      }

      if (!channelResource) {
        console.error(`Cannot find channelId=${ channelId } in channel map`);
        return;
      }

      channelCache[channelId] = await ChannelModel.fromResource(channelResource);
    }

    return channelCache[channelId];
  }

  /**
   * Load channel based on channel self link
   *
   * @param {string} url - Self link URL to search for
   * @return {ChannelModel}
   */
  static async loadFromSelfLink(url) {
    const channels = await ChannelModel.getChannelMap();
    const channelResource = channels.find((ch) => ch.getFirstAction('self').getRawActionUrl() === url);
    const channelId = channelResource.getProp('channelId');

    if (!channelCache[channelId]) {
      channelCache[channelId] = await ChannelModel.fromResource(channelResource);
    }

    return channelCache[channelId];
  }

  static async _propertiesFromResource(resource) {
    const props = resource.getProps();

    return {
      ...await super._propertiesFromResource(resource),
      companyCallSign: props['branchOf/company/callSign'],
      callSign: props.callSign,
      channelId: props.channelId,
      entitled: props.entitled,
      streamId: props.streamId,
      isTveFlag: props.isTve,
      number: props.number,
      enforceParentalControlsOnChannel: props.enforceParentalControlsOnChannel,
      rating: props['contentRating/detailed'],
      isAdult: props.isAdult,
      stationId: props.stationId,
      companyId: props.companyId,
      logo: resource.getFirstAction('logo'),
      contentProvider: {
        name: props.callSign
      },
      tveVariant: resource.getFirstAction('tveVariant').getRawActionUrl()
    };
  }

  /**
   * Build channel from Hypergard resource
   *
   * @param {object} resource - Hypergard resource
   * @return {ChannelModel}
   */
  static async fromResource(resource) {
    return new ChannelModel(await ChannelModel._propertiesFromResource(resource));
  }

  /**
   * Get listing for a specific time
   *
   * Returns the listing that is airing at the specified time
   *
   * @param {number} time - Time to get listing for
   * @return {ListingModel}
   */
  async getListingAt(time) {
    const timeStart = Math.floor(time / 3600e3) * 3600e3; // Round time down to nearest hour

    if (!this.listingCache[timeStart]) {
      const response = await api.send({
        endpoint: 'getTvGridChunk',
        params: {
          startTime: timeStart,
          hours: 1,
          channelIds: this.accountChannelId || this.channelId
        }
      });
      const channel = response.resource.getEmbedded('channels')[0];
      const listingResources = channel.getEmbedded('listings');

      this.listingCache[timeStart] = await Promise.all(listingResources.map(ListingModel.fromResource));
      this.listingCache[timeStart].forEach((listing) => listing.channel = this);
    }

    return this.listingCache[timeStart].find((listing) => listing.startTime <= time && listing.endTime >= time);
  }

  static async getLocalChannelMap(networkAffiliateCompanyId = null) {
    let params = null;
    if (networkAffiliateCompanyId) {
      params = { networkAffiliateCompanyId };
    }

    const localChannelResponse = await api.send({
      endpoint: 'getLocalChannelMap',
      params
    });

    if (!localChannelResponse) {
      return;
    }

    const localChannelResources = localChannelResponse.resource.getEmbedded('channels');
    if (localChannelResources && localChannelResources.length) {
      localChannelResources.forEach((channelResource)=> {
        const localStreamResources = channelResource.getEmbedded('stream');
        const localStreams = [];

        localStreamResources.forEach((stream)=> {
          localStreams.push(Object.assign(stream.getProps(), {
            contentUrl: stream.getFirstAction('contentUrl').getActionUrl('self')
          }));
        });

        localChannels.push(Object.assign(channelResource.getProps(), { streams: localStreams }));
      });
    }
    return localChannels;
  }

  static async getLocalChannelById(channelId) {
    let localChannel = localChannels.find((ch) => ch.channelId === channelId);
    if (!localChannel) {
      const localChannelList = await ChannelModel.getLocalChannelMap();
      localChannel = localChannelList.find((ch) => ch.channelId === channelId);

      if (!localChannel) {
        console.error(`Cannot find channelId=${ channelId } in local channel map`);
        return;
      }
    }
    return localChannel;
  }

  async getLocalChannelByCompanyId() {
    const companyId = this.companyId || this.channel.companyId;
    let localChannel = localChannels.find((ch) => ch.companyId === companyId);

    if (!localChannel) {
      const localChannelList = await ChannelModel.getLocalChannelMap(companyId);
      localChannel = localChannelList.find((ch) => ch.companyId === companyId);

      if (!localChannel) {
        console.error(`Cannot find companyId=${ companyId } in local channel map`);
        return;
      }
    }
    return localChannel;
  }
}

WatchableModel.addType(ChannelModel, ({ _type }) =>
  _type === watchableTypes.Channel);

export default ChannelModel;
