/**
 * @module i18n
 */

import i18next from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import ICU from 'i18next-icu';
import Logger from 'lib/logger';

const logger = new Logger('i18n', { background: 'crimson', color: 'white' });

const i18nInit = (options) => i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(ICU)
  .init({
    fallbackLng: 'en-US',
    debug: false,
    keySeparator: null,
    nsSeparator: null,
    saveMissing: true,
    saveMissingTo: 'current',
    missingKeyHandler: (lng, ns, key, fallback) => {
      if (!i18next.getResourceBundle(lng)) {
        return; // Don't bother logging if the resource bundle isn't loaded
      }

      const escapedKey = key.replace(/\\/g, '\\\\').replace(/"/g, '\\"');
      logger.log('Missing key in %s. Add the following to locale file:\n%c%s',
        lng[0], 'font-weight: bold; margin-left: 5em;', `${ escapedKey }: ${ fallback }`);
    },
    interpolation: {
      escapeValue: false
    },
    detection: {
      order: ['navigator']
    },
    backend: {
      loadPath: '{{lng}}',
      parse: (data) => data,
      ajax: async (lng, options, callback) => {
        try {
          const { default: locale } = await import(
            /* webpackInclude: /\.yaml$/ */
            /* webpackChunkName: "locale-[index]" */
            'locales/' + lng + '.yaml');

          callback(locale, { status: '200' });
        } catch (e) {
          callback(null, { status: '500' });
        }
      }
    },
    ...options
  });

/**
 * Translate a string
 *
 * @see https://www.i18next.com/overview/api#t
 * @return {string}
 */
const t = (...args) => i18next.t(...args);

/**
 * Class decorator to enable localization on a LitElement component
 *
 * Include in the decorator list after `@customElement`.
 *
 * Extends the connectedCallback and disconnectedCallback functions to add a
 * listener for the i18next `languageChanged` event. This event handler will
 * call `requestUpdate` once the language data is ready.
 *
 * @mixin
 *
 * @param {object} target - Class that will be decorated
 * @return {object}
 */
const isLocalized = (target) => ({
  ...target,
  finisher(TargetClass) {
    class LocalizedTargetClass extends TargetClass {
      __languageChangeCallback = () => {
        this.requestUpdate();
      }

      connectedCallback() {
        super.connectedCallback();
        i18next.on('languageChanged', this.__languageChangeCallback);
      }

      disconnectedCallback() {
        super.disconnectedCallback();
        i18next.off('languageChanged', this.__languageChangeCallback);
      }
    }

    return LocalizedTargetClass;
  }
});

export default i18next;
export { t, isLocalized, i18nInit };
