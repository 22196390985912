import API from 'lib/api';
import ActionLoggingMiddleware from 'lib/api/action-logging-middleware';
import ApplicationHeaderMiddleware from 'lib/api/application-header-middleware';
import AuthTokenMiddleware from 'lib/api/auth-token-middleware';
import CustomizeRequestMiddleware from 'lib/api/customize-request-middleware';
import ErrorMiddleware from 'lib/api/error-middleware';
import FingerprintMiddleware from 'lib/api/fingerprint-middleware';
import RetryMiddleware from 'lib/api/retry-middleware';
import RequestLoggingMiddleware from 'lib/api/request-logging-middleware';
import Logger from 'lib/logger';
import config from 'config';

const logger = new Logger('API', { background: 'gold' });
const fingerprintMiddleware = new FingerprintMiddleware({
  fingerprint: `cloudtv_chromecast_${ config.version }_${ config.env }`
});

/**
 * Instance of [API]{@link module:API~API} setup to use XTV API.
 *
 * This includes the middleware setup to provide necessary headers, perform
 * loggin, and retry requests.
 */
const api = new API(config.environment.XTVAPI_ENDPOINT, {
  middleware: [
    new ApplicationHeaderMiddleware().handler,
    new ActionLoggingMiddleware({ logger }).handler,
    new RetryMiddleware().handler,
    new RequestLoggingMiddleware({ logger }).handler,
    new ErrorMiddleware().handler,
    new AuthTokenMiddleware().handler,
    new CustomizeRequestMiddleware().handler,
    fingerprintMiddleware.handler
  ],
  hypergard: {
    cacheHomepage: true,
    preloadHomepage: false,
    xhr: {
      headers: {
        'Accept': 'application/comcast+hal+json, application/json, */*; q=0.01'
      }
    }
  }
});

export default api;
/**
 * Get the most recently used fingerprint for an XTV API request
 * @return {string}
 */
export const getLastFingerprint = () => fingerprintMiddleware.lastFingerprint;
