/**
 * Base class for all models
 */
class BaseModel {
  static async _propertiesFromResource(resource) {
    return {
      _resource: resource
    };
  }

  constructor(props) {
    Object.assign(this, props);
  }

  get selfLinkUrl() {
    if (this._resource.hasAction('self')) {
      return this._resource.getFirstAction('self').getActionUrl();
    }

    return null;
  }
}

export default BaseModel;
